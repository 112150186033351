<script setup lang="ts">
import type {IRate} from "~/interfaces";
const locale = await getLocale("forms", "rate_power_credit")

import BaseForm from "~/components/ui/BaseForm.vue";
import Rata from "~/components/inputs/Rata.vue";

defineComponent({
  name: "RatePowerCreditUpgrade",
});
const emit = defineEmits(["init", "submit", "upgradeRateValue"]);

const model600 = ref<IRate>(getModel(POWER_CREDIT_MIN_CREDIT))
const model2000 = ref<IRate>(getModel(POWER_CREDIT_MAX_CREDIT))
const selected = ref<IRate>(model2000.value);

const text600 = computed(() => {
  return `Rata <strong>${POWER_CREDIT_IMP_RATA} €</strong> per <strong>${POWER_CREDIT_RATE}</strong> mesi <strong>TAEG ${POWER_CREDIT_MIN_TAEG}</strong>`
});

const text600Alt = computed(() => {
  return `<span class="md:hidden"><strong>600</strong> €</span> <span class="md:capitalize">rata</span> <strong>${POWER_CREDIT_IMP_RATA} €</strong> per <strong>${POWER_CREDIT_RATE}</strong> mesi TAEG <strong>${POWER_CREDIT_MIN_TAEG}</strong>`
})

const text2000 = computed(() => {
  return `Rata <strong>${POWER_CREDIT_IMP_RATA} €</strong> TAEG <strong>${POWER_CREDIT_MAX_TAEG}</strong>`
});

const text2000Alt = computed(() => {
  return `<span class="md:hidden"><strong>2.000</strong> €</span> <span class="md:capitalize">rata</span> ${POWER_CREDIT_IMP_RATA} € TAEG ${POWER_CREDIT_MAX_TAEG}`
});

const isActive = (model: IRate) => {
  return selected.value.imp_credito === model.imp_credito &&
      selected.value.rate === model.rate
};
const toggleSelected = (rate: IRate) => {
  if (window.innerWidth < 1024) selected.value = rate;
};

const submit = (model: IRate) => {
  emit("submit", model);
};
onMounted(() => emit("init"))
</script>

<template>
  <BaseForm>
    <template #upper>
      <div class="offers my-4 sm:my-8">
        <div class="px-2 sm:px-16 mx-auto lg:container pt-6">
          <ul class="mb-4 lg:mb-6" data-testid="rataOur600">
            <Rata
                :model="model600"
                :active="isActive(model600)"
                type="our"
                :text="text600"
                :textAlt="text600Alt"
                @selected="toggleSelected"
                @submit="submit"
            />
          </ul>
          <div>
            <h2
                class="text-orange text-center font-semibold text-xl sm:text-3xl mb-4 mt-10"
            >
              {{ locale.title_power_upgrade }}
            </h2>
            <h3 class="text-base text-center mb-6">
              {{ locale.subtitle_power_upgrade }}
            </h3>
          </div>
          <div class="mb-12" data-testid="rataOur2000">
            <Rata
                class="mb-2"
                :model="model2000"
                :active="isActive(model2000)"
                :text="text2000"
                :textAlt="text2000Alt"
                type="our"
                @selected="toggleSelected"
                @submit="submit"
            />
          </div>
        </div>
      </div>
      <div class="spacer h-6"></div>
    </template>
  </BaseForm>
</template>

<style scoped>

</style>
